.report-page .date-picker-report {
    height: 48px !important;
    border-radius: 0.375rem;
    width: 500px;
  }
.report-page .lotus-select {
    width: 500px;
}
.btn-report {
    height: 48px !important;
}