// 
.lotus-select {
  position: relative;

  .ant-select-arrow span {
    color: #424242;

    &.ant-form-item-feedback-icon-error span {
      color: #ff4d4f !important;
    }
  }

  .ant-select-selector {
    height: 48px !important;
    // padding: 0 11px 0 42px !important;

    .ant-select-selection-placeholder {
      transition: all 0.2s ease-in-out;
      position: relative;
      top: 3px;
    }
  }

  .lotus-select-has-value,
  .ant-select-focused,
  &:has(.ant-select .ant-select-selector .ant-select-selection-item[title]) {
    .ant-select-selector {
      position: relative !important;
      display: block;

      .ant-select-selection-search input {
        position: relative;
        top: 10px;
        margin-left: 32px;
      }

      .ant-select-selection-placeholder {
        top: 10px;
        color: transparent;
      }

      .ant-select-selection-item {
        position: relative;
        top: 12px;
        font-weight: 500;
        color: #424242;
      }
    }
  }

  .ant-select-focused,
  .lotus-select-has-value {
    &+.lotus-select-placeholder {
      position: absolute;
      opacity: 1;
      top: 4px;
    }
  }
  &:has(.ant-select .ant-select-selector .ant-select-selection-item[title]) {
    .lotus-select-placeholder {
      position: absolute;
      opacity: 1;
      top: 4px;
    }
  }

  .ant-select-status-error  +.lotus-select-placeholder {
    color: #ff4d4f;
  }


  .lotus-select-placeholder {
    color: #7D7D7D;
    font-size: 12px;
    font-style: normal;
    position: absolute;
    top: 0px;
    left: 13px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  .lotus-select-prefix-icon {
    color: #424242;
    position: absolute;
    top: 4px;
    left: 12px;
    z-index: 10;
    font-size: 20px;
    font-weight: bold;
  }

  .lotus-select-prefix-icon ~.ant-select .ant-select-selector {
    height: 48px !important;
    padding: 0 11px 0 42px !important;
  }

  .lotus-select-prefix-icon ~.lotus-select-placeholder {
    left: 42px;
  }
}