// 
.lotus-input {
  position: relative;

  input.ant-input,
  input.ant-input-number-input  {
    transition: all 0.2s ease-in-out;
    height: 47px !important;
    
    &::placeholder {
      transition: all 0.2s ease-in-out;
    }
    
    &:focus, &.populated {
      padding-top: 26px;
      padding-bottom: 9px;
      
      &::placeholder {
        color: transparent;
      }
      
      & + span {
        opacity: 1;
        top: 10px;
      }
    }
  }

  .ant-input-focused,
  .ant-input-number-affix-warpper-focused,
  .ant-input-number-focused .ant-input-number-input-wrap input,
  .lotus-select-has-value {

    &+.lotus-input-placeholder {
      position: absolute;
      opacity: 1;
      top: 6px;
    }
  }

  &:has(.ant-input-number .ant-input-number-input-wrap input:focus),
  &:has(.ant-input-number .ant-input-number-input-wrap input[aria-valuenow]) {
    .ant-input-number-input {
      padding-top: 26px;
      padding-bottom: 9px;
    }
    .lotus-input-placeholder {
      position: absolute;
      opacity: 1;
      top: 6px;
    }
  }

  .ant-input-number-affix-wrapper-status-error +.lotus-input-placeholder {
    color: #ff4d4f;
  }

  .lotus-input-placeholder {
    color: #7D7D7D;
    font-size: 12px;
    font-style: normal;
    position: absolute;
    top: 0px;
    left: 13px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    z-index: 99;
  }
}