.ant-btn {
  @apply bg-secondary border-secondary;

  &:not(.ant-btn-circle) {
    @apply rounded-lg;
  }

  &:hover,
  &:focus {
    @apply border-primary border border-solid text-primary;
  }

  &.ant-btn-dashed {
    @apply border border-solid border-primary text-primary;
  }

  &.ant-btn-primary {
    @apply border-primary bg-primary text-white;

    &:hover,
    &:focus {
      @apply text-white border-primary bg-primary border-opacity-90 bg-opacity-90;
    }
  }

  &.ant-btn-primary[disabled] {
    @apply border-opacity-70 bg-opacity-70;

    &:hover,
    &:focus {
      @apply text-white border-secondary;
    }
  }

  &.ant-btn-link {
    @apply bg-transparent border-transparent text-primary ;

    &:hover,
    &:focus {
      @apply border-transparent text-opacity-70;
    }

    span {
      @apply underline;
    }
  }
}


.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-light .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover {
  @apply text-primary;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after {
  @apply border-primary;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  @apply bg-teal-50 text-primary;
}

.ant-page-header {
  .ant-page-header-heading {
    .ant-page-header-heading-left {
      .ant-page-header-heading-title {
        font-size: 28px;
      }
    }
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  @apply rounded-lg;
}

.ant-input {
  @apply rounded-lg;
}

span.ant-radio+* {
  position: relative;
  top: -3px;
}

.ant-radio {
  font-size: 38px;
  top: 0.1em;

  .ant-radio-inner {
    width: 26px;
    height: 26px;
    border-width: 3px;

    &::after {
      width: 26px;
      height: 26px;
      margin-top: -13px;
      margin-left: -13px;
    }
  }

  &.ant-radio-checked {
    .ant-radio-inner {
      @apply border-primary;

      &:after {
        @apply bg-primary;
      }
    }
  }

  &:hover {
    .ant-radio-inner {
      @apply border-primary;
    }
  }
}

.ant-checkbox+span {
  position: relative;
  top: -2px;
}

.ant-checkbox-checked::after {
  @apply border-primary;
}

.ant-checkbox-wrapper .ant-checkbox-inner {
  @apply border-primary;
  width: 20px;
  height: 20px;

}

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  @apply border-primary;
  width: 20px;
  height: 20px;

}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    @apply bg-primary border-primary;
    width: 20px;
    height: 20px;

    &::after {
      top: 45%;
      width: 6.714286px;
      height: 13.142857px;
    }
  }
}

// step
.ant-steps {

  .ant-steps-item-process,
  .ant-steps-item.ant-steps-item-finish {
    .ant-steps-item-icon {
      @apply border-primary bg-primary;
    }
  }

  .ant-steps-label-vertical .ant-steps-item-tail {
    margin-left: 58px;
    padding: 13.5px 24px;
  }

  .ant-steps-item-tail {
    top: 22px;
  }


  .ant-steps-item-finish {
    &>.ant-steps-item-container>.ant-steps-item-tail::after {
      height: 2px;
      @apply bg-primary;
    }
  }

  .ant-steps-item-process {
    &>.ant-steps-item-container {
      &>.ant-steps-item-tail::after {
        @apply bg-gray-400;
      }
    }
  }

  .ant-steps-item-wait {
    &>.ant-steps-item-container {
      &>.ant-steps-item-icon {
        @apply border-gray-400 bg-gray-400;
      }

      &>.ant-steps-item-tail::after {
        @apply bg-gray-400;
      }
    }
  }

  .ant-steps-item-icon {

    width: 50px;
    height: 50px;
    font-size: 24px;
    margin-left: 34px;

    .ant-steps-icon {
      top: 8px;
      color: #ffffff;

      .anticon.anticon-check.ant-steps-finish-icon {
        top: -5px;
        position: relative;
        color: #ffffff;
      }
    }
  }
}

.ant-modal {
  .ant-modal-header,
  .ant-modal-content {
    @apply rounded-lg;

    .ant-modal-footer {
      @apply p-4;
    }
  }
}

// Tab
.ant-tabs {
  .ant-tabs-nav-wrap {
    .ant-tabs-nav-list {

      .ant-tabs-tab {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        background-color: #E0E0E0;
        padding: 16px 16px;
        min-width: 150px;

        &.ant-tabs-tab-active {
          @apply bg-white;

          .ant-tabs-tab-btn {
            @apply text-primary;
          }
        }

        &:hover, .ant-tabs-tab-btn:active {
          @apply text-primary;
        }

        .ant-tabs-tab-btn {
          @apply m-auto;
        }
      }

      .ant-tabs-tab+.ant-tabs-tab {
        @apply m-0;
      }

      .ant-tabs-ink-bar {
        @apply bg-primary h-1;
      }
    }
  }

  .ant-tabs-nav {
    @apply m-0;
  }
}