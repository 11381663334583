@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";
@import '~antd/dist/antd.css';
@import "./styles/antd.override.scss";

@layer base {
  h1 {
    @apply text-3xl text-inherit;
  }
  h2 {
    @apply text-2xl text-inherit;
  }
  h3 {
    @apply text-xl text-inherit;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  @apply font-primary;
}

.ant-layout-header,
.ant-layout-content {
  @apply bg-white;
}

.ant-layout-content {
  margin: 16px;
  padding: 24px;
  border-radius: 8px;
}

.ant-layout-header {

  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after {
    border-bottom: none;
  }

  .menu-divider {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

#app-layout {
  .ant-layout-sider {
    @apply bg-transparent;

    .ant-layout-sider-dark:not(.ant-layout-sider-collapsed) {
      .ant-layout-sider-children {
        .sidenav-content {
          .ant-menu-dark {

            .ant-menu-item:hover,
            .ant-menu-item-active,
            .ant-menu-submenu-active,
            .ant-menu-submenu-open,
            .ant-menu-submenu-selected,
            .ant-menu-submenu-title:hover {
              background: #E8E8E8;
            }
          }
        }
      }
    }

    .ant-layout-sider-children {
      @apply bg-white font-bold;

      .sidenav-content {

        .ant-menu.ant-menu-dark,
        .ant-menu-dark .ant-menu-sub,
        .ant-menu.ant-menu-dark .ant-menu-sub {
          @apply bg-white text-black;
        }

        .ant-menu-dark .ant-menu-item,
        .ant-menu-dark .ant-menu-item-group-title,
        .ant-menu-dark .ant-menu-item>a,
        .ant-menu-dark .ant-menu-item>span>a {
          @apply text-black;
        }

        .ant-menu-item,
        .ant-menu-submenu {
          height: 56px;

          &.ant-menu-submenu-active,
          &.ant-menu-submenu-open {
            .ant-menu-submenu-title {

              .ant-menu-submenu-expand-icon,
              .ant-menu-submenu-arrow {

                &::before,
                &::after {
                  @apply bg-black;
                }
              }
            }
          }

          .ant-menu-submenu-title {
            height: 56px;

            .ant-menu-submenu-expand-icon,
            .ant-menu-submenu-arrow {

              &::before,
              &::after {
                @apply bg-white;
              }
            }
          }
        }

        .ant-menu-dark {

          .ant-menu-item:hover,
          .ant-menu-item-active,
          .ant-menu-submenu-active,
          .ant-menu-submenu-open,
          .ant-menu-submenu-selected,
          .ant-menu-submenu-title:hover {
            @apply text-black;

            .ant-menu-submenu-expand-icon,
            .ant-menu-submenu-arrow {
              @apply text-black;
            }
          }

          .ant-menu-item-selected>a,
          .ant-menu-item-selected>span>a {
            @apply text-white;
          }

          .ant-menu-item-selected .ant-menu-item-icon,
          .ant-menu-dark .ant-menu-item-selected .anticon {
            @apply text-black;
          }

          .ant-menu-item:hover>a,
          .ant-menu-item-active>a,
          .ant-menu-submenu-active>a,
          .ant-menu-submenu-open>a,
          .ant-menu-submenu-selected>a,
          .ant-menu-submenu-title:hover>a,
          .ant-menu-item:hover>span>a,
          .ant-menu-item-active>span>a,
          .ant-menu-submenu-active>span>a,
          .ant-menu-submenu-open>span>a,
          .ant-menu-submenu-selected>span>a,
          .ant-menu-submenu-title:hover>span>a {
            @apply text-black;
          }

          &.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
            @apply bg-white text-black;

            >a,
            >span>a {
              @apply text-black;
            }
          }
        }
      }
    }

    .ant-layout-sider-trigger {
      @apply bg-primary;
    }

    &.ant-layout-sider-collapsed {

      .ant-menu-item,
      .ant-menu-submenu .ant-menu-submenu-title {
        padding: 0 calc(20% - 4px);
        height: 56px;
      }

      .ant-menu.ant-menu-inline-collapsed>.ant-menu-item .ant-menu-item-icon,
      .ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon {
        @apply w-14 h-14 text-black;

        svg {
          font-size: 20px;
          position: relative;
          top: 5px;
        }
      }

      .ant-menu-item:hover,
      .ant-menu-item-active,
      .ant-menu-submenu-active,
      .ant-menu-submenu-open,
      .ant-menu-submenu-selected,
      .ant-menu-submenu-title:hover {


        .ant-menu-item-icon,
        .ant-menu-submenu-title .ant-menu-item-icon {
          background: #E8E8E8;
          @apply rounded-lg;
        }

        .ant-menu-submenu-expand-icon,
        .ant-menu-submenu-arrow {
          @apply text-black;
        }

      }
    }
  }
}

.site-layout .site-layout-background {
  background-color: transparent !important;
  margin: -16px 0 0;
  padding: 0 24px 24px;
}

.ant-pagination {
  @apply relative;

  .ant-pagination-options {
    @apply absolute left-0 ml-0;

    &::before {
      content: 'แสดง';
      margin-right: 0.5rem;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      @apply rounded-lg;
    }

    .ant-select-dropdown  {
      .ant-select-item-option-content {
        @apply text-center;
      }
    }
  }

  .ant-pagination-item {
    @apply rounded-lg;

    &:hover {

      @apply border-primary;

      a {
        @apply text-primary;
      }
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      @apply rounded-lg;
    }

  }

  .ant-pagination-item-active {
    @apply bg-primary border-primary;

    a {
      @apply text-white;
    }
  }
}

// icons
span.anticon {

  &.anticon-left,
  &.anticon-right,
  &.anticon-plus,
  &.anticon-arrow-left {
    @apply relative -top-1;
  }
}


form[id^="search-"] {
  .ant-form-item {
    @apply mb-0;

    .ant-form-item-control-input {

      .ant-input-affix-wrapper,
      .ant-select .ant-select-selector,
      .ant-picker {
        @apply rounded-md;
      }
    }
  }
}

.ant-btn.btn-submit {
  background-color: #E8E8E8;

  &:hover,
  &:focus {
    background-color: #ffffff;
    border-color: #E8E8E8;
    color: #000000;
  }
}

.ant-table-tbody>tr>td {
  .btn-view {
    @apply text-gray-400;
  }
}

.ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-tbody>tr>td.ant-table-cell-row-hover {
  .btn-view {
    @apply font-bold text-primary;

    &:hover {
      @apply text-white bg-primary;
    }
  }
}

.custom-radio-square {
  .ant-radio-inner {
    border-radius: 0 !important;
    width: 18px;
    height: 18px;
    border-width: 1px;

    &::after {
      border: none !important;
      
    }
  }
}
.reject-modal-btn {
  width: 200px !important;
  @apply border-reject bg-reject text-white;

    &:hover,
    &:focus {
      @apply text-white border-reject bg-reject border-opacity-90 bg-opacity-90;
    }
}
.approve-modal-btn {
  width: 200px !important;
}

.goback-modal-btn {
  width: 200px !important;
}

.payment-approval-modal {
  .file-display {
    display: flex !important;
  }
  .ant-modal-footer {
    text-align: center;
  }
}

.sidenav-footer {
  position: absolute;
  bottom: 0;
  padding: 24px;
}

.ant-table {
  display: block;

  @media screen and (max-width: 1360px) {
    .column-order-no {
      white-space: unset;
    }
    .list-tag-status{
      white-space: unset;
    }
    .column-order-no {
      width: 12%;
    }
    .column-crop-no {
      width: 10%;
    }
    .column-crop-name {
      width: 14%;
    }
    .column-type {
      width: 9%;
    }
    .column-value {
      width: 14%;
    }
    .column-collection {
      width: 9%;
    }
    .column-status {
      width: 14%;
    }
    .column-create-at {
      width: 10%;
    }
    .column-more-detail {
      width: 8%;
    }
  }

  @media screen and (min-width: 1360px) {
    .column-order-no {
      width: 12%;
    }
    .column-crop-no {
      width: 10%;
    }
    .column-crop-name {
      width: 15%;
    }
    .column-type {
      width: 10%;
    }
    .column-value {
      width: 10%;
    }
    .column-collection {
      width: 10%;
    }
    .column-status {
      width: 15%;
    }
    .column-create-at {
      width: 10%;
    }
    .column-more-detail {
      width: 8%;
    }
  }

}